@media (max-width: 1600px) {
  .card-icon-box-1 {
    width: 112px;
    height: 120px;
  }
  .dasboard-card {
    height: 164px;
  }
  .dasboard-card .card-img-overlay {
    padding: 20px;
  }
}
@media (min-width: 1281px) and (max-width: 1580px) {
  .dasboard-card .card-text {
    font-size: 20px !important;
    line-height: 25px !important;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 1400px) {
  .sidebar-scroll {
    height: 70vh;
    overflow-y: scroll;
  }
  .input_file_f img {
    height: 130px;
    width: 130px;
  }
  .card_small label {
    font-size: 14px;
  }
  .card_small h6 {
    font-size: 14px;
  }
  .head h2 {
    font-size: 15px;
  }
  .upload_btn {
    position: relative;
    margin-top: 59px;
    display: block;
  }
}

@media (max-width: 1300px) {
  .icons_right p {
    font-size: 12px;
  }
  .card_small h6 {
    font-size: 13px;
  }
  .card_small h6 {
    font-size: 13px;
  }
  .card_small label {
    font-size: 13px;
  }
  .dlt_acount .btn.btn-danger {
    font-size: 13px;
  }
  .card_small input::placeholder {
    font-size: 13px;
  }
  .sub_btn .btn {
    font-size: 13px;
  }
  .upload_btn p {
    font-size: 13px;
  }
  .social ul li p {
    font-size: 13px;
  }
  .inputs_main input {
    padding: 13px 15px !important;
    font-size: 13px;
  }
  .inputs_main select {
    padding: 16px 15px !important;
    font-size: 13px;
  }
  .card_small .form-control {
    padding: 13px 15px !important;
    font-size: 13px;
  }
  .upload_btn p {
    width: 95px;
    height: 37px;
  }
  .sub_btn .btn {
    padding: 8px 28px;
  }
  .dlt_acount .btn.btn-danger {
    padding: 10px 22px;
  }
  .phone_select input {
    padding: 0 !important;
  }
  .phone_select .css-anmkw4-singleValue {
    font-size: 14px;
  }
  .select-refl {
    font-size: 12px;
  }
  .theory-list .list-title {
    font-size: 18px;
  }
  .meditation-info img {
    height: 40px;
  }
  .icon-number {
    font-size: 23px;
  }
  .card-icon-box-1 {
    width: 93px;
    height: 98px;
  }
  .card-icon-box-1 img {
    width: 40px;
  }
  .dasboard-card {
    height: 133px;
  }
}
@media (max-width: 1200px) {
  .input_file_f img {
    height: 100px;
    object-fit: cover;
    width: 100px;
  }
  .icons_list ul li {
    padding: 14px 4px;
  }
  .upload_btn {
    position: relative;
    margin-top: 88px;
    display: block;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .process-figur {
    display: block;
  }
  .CircularProgressbar {
    width: 40% !important;
  }
  .collapsed .top-header {
    width: 85% !important;
  }
  .figure-img {
    width: 120px !important;
    height: 120px !important;
  }
  .profile-btn .btn {
    margin: 0px 0px 8px 0px;
  }
  .process-figur .btn {
    font-size: 13px;
  }
  .photo-gallery .hstack > div {
    padding: 0px !important;
  }
  .profile-btn .btn {
    font-size: 12px;
  }
  .user-info .hstack {
    display: block;
  }
  .dasboard-card .card-img-overlay {
    padding: 1rem;
  }
  .dasboard-card .card-text {
    font-size: 19px !important;
    line-height: 20px !important;
    margin-bottom: 0.5rem;
  }

  .gender-chart {
    height: 300px !important;
    width: 300px !important;
  }
  .icons_right {
    padding-left: 12px;
  }
}
@media (max-width: 1180px) {
  .border-0.p-4.rounded-card.self-reflection-body {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .gender-chart {
    height: 260px !important;
    width: 260px !important;
  }
}

@media (min-width: 850px) and (max-width: 1024px) {
  .serch-feild {
    width: 223px !important;
  }
  .searchicon2 {
    display: none;
  }
  .left-header-section {
    margin-top: -23px;
  }
  ul.InfiniteCarouselScrollTrack li {
    width: 77.1667px !important;
  }
  .user-info .hstack {
    display: block;
  }
  .dasboard-card .card-img-overlay {
    padding: 1rem;
  }

  .dasboard-card .card-text {
    font-size: 17px;
    line-height: 19px;
  }
  .dasboard-card .card-title {
    margin-bottom: 0.5rem;
    font-size: 14px;
  }
  .card-icon-box-1 img {
    width: 50%;
  }
  .rate-box {
    position: relative;
    right: 0;
  }

  .card-meditation .card-title {
    font-size: 20px;
  }
}
@media (max-width: 991.99px) {
  .border-0.p-4.rounded-card.self-reflection-body {
    padding: 20px !important;
  }
  .icons-list img {
    margin-left: 6px;
  }
  .meditation-info img {
    height: 30px;
  }
  .theory-list .list-title {
    font-size: 15px;
  }
  .self-reflection-main .list-title p {
    font-size: 13px;

    margin: 0;
  }
  .profile-img {
    width: 130px;
    height: 130px !important;
  }
  .user-info .figure-caption {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .rate-box {
    height: 100px;
    color: #9f8453;
    position: unset;
    margin-top: 14px;
    width: 100%;
    top: 0;
  }
  .user-info {
    display: flex !important;
    flex-wrap: wrap;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .collapsed .top-header {
    width: 80% !important;
  }
  .serch-feild {
    width: 100% !important;
  }
  .searchicon2,
  .searchicon {
    display: none;
  }
  ul.InfiniteCarouselScrollTrack li {
    width: 77.1667px !important;
  }
  .wrap-content,
  .content-body {
    margin-left: 8rem;
  }
  #header {
    z-index: 2;
  }
  .user-info .hstack,
  .user-info {
    display: block !important;
  }
  .rate-box {
    position: relative;
    right: 0;
  }
}
@media (max-width: 768px) {
  .content-body.px-4.wrap-content {
    padding: 12px !important;
  }
}
@media (max-width: 576px) {
  .gender-chart {
    height: 225px !important;
    width: 100% !important;
  }
  .self-reflection-main .theory-list .list-group-item {
    margin-right: 0;
  }
  .border-0.card-user-detail.rounded-card.meditation-info.px-4.mt-4.mb-5.card {
    padding-top: 20px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .icon-number {
    max-width: 18px;
  }
  .width-70-card {
    width: 100%;
  }
  .icons-list.width-30-card {
    margin-top: 20px;
  }
  .theory-list .list-title {
    font-size: 15px;
  }
  .dasboard-card .card-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .list-group .list-group-item {
    padding: 15px 5px !important;
  }
  .meditation-info h2 {
    font-size: 20px;
    margin-bottom: 18px;
    text-align: center;
  }
  button.add-category-btn.new-self-ref.btn {
    width: 180px;
    max-width: 100%;
    font-size: 13px;
    margin: auto;
  }
  .meditation-info img {
    height: 30px;
    margin-bottom: 10px;
  }
  .rounded-card h3 {
    font-size: 18px;
  }
  .custom-table th {
    font-size: 13px;
    font-weight: 500;
  }
  .custom-table td {
    font-size: 13px;
  }
  .secondary-drop-area .btn-primary {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .mb-5 {
    margin-bottom: 5px !important;
  }
  .border-0.p-4.rounded-card.self-reflection-body {
    padding: 10px !important;
  }
  .card-meditation .card-title {
    margin-bottom: 0.5rem;
    color: #000;
    font-size: 17px;
  }
  .card-meditation .card-text {
    font-size: 14px;
    color: #8e8e8e;
    margin-top: -5px;
  }
  .meditation-info .btn-primary,
  .meditation-model .btn {
    font-size: 15px;
  }
  .modal-content-80 .modal-content {
    width: 100% !important;
    margin: auto;
  }
  .meditation-model .modal-content {
    padding: 25px;
  }
  .meditation-model .modal-title {
    font-size: 25px !important;
  }
  .upld-place-click p {
    margin: 0;
    font-size: 13px;
  }
  .modal-footer {
    flex-wrap: nowrap;
  }
  .card-user-detail {
    padding: 15px;
  }
  .list-group .list-group-item {
    font-size: 12px !important;
  }
  .icon-number {
    font-size: 17px;
  }
  .upld-audio-title {
    color: #141617;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .rounded-card.faqs.card {
    padding: 28px 10px;
    margin-top: 30px;
  }
  .top-flex.justify-content-between.mb-5 {
    margin: 0 !important;
  }
  .figure.user-info .figure-caption {
    padding: 10px !important;
  }
  .profile-img {
    width: 90px;
    height: 90px !important;
  }
  .list-card-play .ms-2.d-flex.align-items-center.width-70-card {
    margin: 0 !important;
  }
}

@media (min-width: 320px) and (max-width: 499px) {
  .show-btn a {
    margin-top: -15px;
  }
  .wrap-content,
  .content-body {
    margin-left: 8rem;
  }
  #header {
    z-index: 2;
  }
  .d-flex,
  .user-info {
    display: block !important;
  }
  .serch-feild {
    width: 100% !important;
  }
  .searchicon2 {
    display: none;
  }
  .card-user-detail {
    padding: 5px;
  }
  .rate-box {
    position: relative;
    right: 0;
  }

  .card-meditation {
    padding: 10px;
    margin-bottom: 10px;
  }
  .top-flex {
    display: block;
  }

  .icon-number {
    font-size: 18px;
    width: 38px;
    height: 20px;
  }

  .icons-list img {
    width: 25%;
  }
  .theory-list li {
    display: block !important;
  }
}

@media (max-width: 480px) {
  button.add-category-btn {
    width: 100%;
  }
  .collapsed .footer {
    width: 100% !important;
    margin-left: 0rem !important;
  }
  .footer {
    margin-left: 0rem !important;
  }
}
