body {
  background-color: #f2f8ff !important;
  font-family: "Roboto", sans-serif !important;
}
a {
  text-decoration: none !important;
}

/* Login css */
.sign-up-card .btn {
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px #e9dfcd;
}
.bg-gray {
  background-color: #e5eeda;
}
.login-bg {
  background-color: #fff;
}
.btn-primary {
  background-color: #b79c6c !important;
  border-color: #b79c6c !important;
}
.text-primary-clr {
  color: #b79c6c !important;
}
.footer {
  position: fixed;
  bottom: 0px;
  width: 100% !important;
  margin-left: 17rem !important;
  margin-top: 0px;
  z-index: 1;
  background: #f2f8ff;
}

/* Top Header */
.footer .text-muted {
  font-size: 14px;
}
.dropdown-item-text {
  padding: 0 1rem;
  color: #7b7b7b !important;
  font-size: 15px;
}
.top-header .hstack svg {
  height: 17px;
  width: 20px;
  margin-top: -3px;
}
.top-header .dropdown-item {
  font-size: 15px;
  font-weight: 500;
}

.searchicon2 {
  position: absolute;
  display: block;
  width: 1.275rem;
  height: 1.275rem;
  line-height: 2.375rem;
  color: #aaa;
  top: 16px;
  left: 310px;
}
.top-header .nav-item a {
  color: #808b96;
  font-weight: 600;
}

.content-body {
  margin-left: 16rem;
  margin-bottom: 30px;
}
.wrap-content {
  margin-left: 9rem;
}
.top-header {
  position: sticky;
  top: 0;
  background: #f2f8ff;
  z-index: 1;
}
.collapsed .top-header {
  width: 90% !important;
  margin-left: 10rem !important;
}
.collapsed .footer {
  width: 90% !important;
  margin-left: 10rem !important;
}
.top-header .dropdown-toggle::after {
  display: none !important;
}
.menuarea {
  width: 220px;
  border: none !important;
  border-radius: 15px !important;
}

button.dropdown-toggle:focus {
  box-shadow: none !important;
}
.sign-up-card {
  width: 100%;
  max-width: 500px;
}
.top-header img {
  width: 60%;
}

/* sidebar css start */
.logotext img {
  max-width: 45%;
}
.collapsed .logotext img {
  max-width: 83%;
}
.SidebarContent {
  position: relative;
  top: -130px;
}
.closemenu::after {
  content: url("../images/icon-bg") !important;
  position: relative;
  z-index: 18;
  left: 236px;
  top: -30px;
  background-size: 100%;
}
.collapsed .closemenu::after {
  content: inherit !important;
}
.collapsed .closemenu::before {
  content: url("../images/icon-bg") !important;
  position: relative;
  z-index: 18;
  left: 108px;
  top: -16px;
  background-size: 100%;
}

.collapsed span.ps-active {
  background: #b79c6c;
  border-radius: 10px;
  box-shadow: rgba(70, 41, 235, 0.15) 0px 48px 100px;
}
.collapsed span.ps-menu-icon {
  width: 50px !important;
  min-width: 50px !important;
  height: 47px !important;
}
.collapsed .ps-active svg {
  stroke: #fff !important;
}
.collapsed .SidebarContent {
  background-color: #fff !important;
}
#header {
  position: fixed;
  width: 258px;
  background-color: #fff;
  z-index: 1;
}

.side-icon {
  color: #fff;
  position: absolute;
  right: -12px;
  z-index: 9999;
  line-height: 22px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 145px;
  cursor: pointer;
  background: #b79c6c;
  height: 30px;
  width: 30px;
  padding: 5px;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}
.collapsed {
  width: 130px !important;
  min-width: 80px;
}
.ps-menu-icon svg {
  stroke: #989ead !important;
  height: auto;
  width: 21px;
}
.css-ewdv3l .ps-menu-label {
  color: #3c3d51;
  font-weight: 600;
}
span.ps-active {
  color: #b79c6c !important;
}
span.ps-active svg {
  stroke: #b79c6c !important;
}

/* User Managment Page css */
.coaches-table .btn-danger,
.coaches-table .btn-danger:hover {
  background-color: #f5776e;
  border-color: #f5776e;
}
.user-section .bg-success {
  background-color: #68c281 !important;
}
.user-section .bg-danger {
  background-color: #f5776e !important;
}
.user-section td a {
  color: #000;
}
.btn-light {
  background-color: #fff !important;
  border-color: #fff !important;
}
.secondary-drop-area .btn-primary {
  background-color: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.dropdown-menu.show {
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.badge {
  padding: 0.65em 0.65em !important;
}
.css-wx7wi4 {
  width: 25px !important;
  min-width: 25px !important;
}
.btn-secondary {
  background-color: #fe1d89 !important;
  border-color: #fe1d89 !important;
}
.user-info .btn-info {
  color: #ffffff !important;
  background-color: #7490fe !important;
  border-color: #7490fe !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.nav-tabs {
  border-bottom: 1px solid #ffffff !important;
}
.nav-tabs .nav-link.active {
  border-color: transparent !important;
  border-bottom: 2px solid #3c57d9 !important;
  font-weight: 600;
}
.first-td tr {
  color: #b79c6c !important;
  background-color: #f9f5ef !important;
  font-size: 15px;
}
.custom-table > :not(caption) > * > * {
  padding: 1.5rem 1.5rem !important;
}
.custom-table tr {
  border-bottom: 1px solid #ededed !important;
}

.custom-table td {
  font-size: 16px;
  color: #3c3d51;
  font-weight: 600;
}
.custom-table th {
  font-weight: 500;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}
.image-gallery p {
  color: #808b96;
  font-size: 13px;
  font-weight: 500;
}
.user-section .form-control {
  background-color: #f6f6f6 !important;
  border: 1px solid #f2f8ff !important;
  padding: 1rem -0.25rem !important;
}

.serch-feild {
  width: 350px !important;
}
.show-btn a {
  color: #3c3d51 !important;
  font-weight: 500;
  text-decoration: underline;
  margin-top: -35px;
}
.image-gallery a {
  text-decoration: none;
  color: #000;
}
.table-btn .btn-success,
.table-btn .btn-success:hover {
  color: #ffffff;
  background-color: #efeafa;
  border-color: #efeafa;
}
.table-btn .btn {
  padding: 0.375rem 1.75rem;
  border-radius: 7px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}
.coaches-table .btn-light,
.coaches-table .btn-light:hover {
  background-color: #c1c9b6 !important;
  color: #fff;
}
.coaches-table .bg-warning {
  background-color: #f5a76e !important;
}
/* User Detail page css */
.list-group {
  display: block !important;
}
.list-group .list-group-item {
  display: inline-block;
  border: none;
  background-color: #f9f9f9;
  font-size: 14px !important;
  padding: 15px 15px !important;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #ededed;
  margin-right: 15px;
  margin-bottom: 15px;
}
.w-70 {
  width: 180px;
  height: 160px;
}
.bg-danger-info {
  background-color: #fee8f2;
  border-radius: 30px !important;
  font-size: 14px !important;
  padding: 11px 20px !important;
}
.profile-img {
  width: 170px;
  height: 170px !important;
}
.shadow-user-info {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  border: 1px solid #e7e7e7 !important;
}

.bg-success {
  background-color: #68c281 !important;
}
.custom-table .bg-primary {
  background-color: #7490fe !important;
}
.user-info .figure-caption .badge {
  font-size: 11px;
  font-weight: 800;
}
.hstack {
  display: flex;
}
.user-info .hstack svg {
  height: 15px;
  width: 15px;
  color: #fff !important;
}
.hstack span {
  font-size: 16px;
  font-weight: 600;
  color: #3c3d51;
}
.profile-btn .btn {
  font-size: 14px;
  font-weight: 700;
  color: #3c3d51 !important;
  border-radius: 8px;
  margin: 0px 7px 0px 0px;
}
.profile-btn .btn-warning,
.profile-btn .btn-warning:hover {
  background-color: #ffc1072e;
  border-color: #ffc10700;
}
.profile-btn .btn-info {
  background-color: #0dcaf024 !important;
  border-color: #0dcaf000 !important;
}
.CircularProgressbar {
  width: 32% !important;
  vertical-align: middle;
}
.process-figur {
  background-image: url("../images/processbg.png");
  background-color: #eef1ff;
  background-size: cover;
  border: 3px solid #fff;
  border-radius: 10px;
}
.process-figur .btn {
  border-radius: 30px;
  background-color: #3c57d9 !important;
  border-color: #3c57d9 !important;
}
.title-bg {
  background-color: #808b9626;
  font-size: 18px;
  color: #3c3d51;
  font-weight: 700;
  padding: 12px;
  border-radius: 10px;
}
.social-info {
  display: inline-flex;
  flex-wrap: wrap;
}
.social-info > div {
  margin: 6px;
}
.social-info strong {
  color: #180f2a;
  font-weight: 700;
}
.social-info .text-muted {
  font-size: 13px;
}
.page-link {
  color: #808b96;
  font-weight: 600;
}
.pagination .page-item {
  margin: 5px;
}

.pagination_new .pagination {
  justify-content: center;
  margin-top: 20px;
}

.pagination_new ul li a {
  border: 1px solid #dee2e6;
  position: relative;
  display: block;
  padding: 10px 15px;
  line-height: 1.25;
  font-size: 13px;
  background-color: #fff;
  color: #000;
}
.pagination_new ul li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination_new ul li.active a {
  z-index: 1;
  color: #fff !important;
  background-color: #b79c6c;
  border-color: #b79c6c;
}
.pagination_new .previous.disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background: transparent;
  border-color: #dee2e6;
  /* display: 'none'; */
}
.pagination_new ul li:last-child a {
  margin-left: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  /* display: none; */
}
.pagination_new .next.disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background: transparent;
  border-color: #dee2e6;
}

.figure-caption p {
  font-size: 16px;
}

.rounded-card {
  border-radius: 30px !important;
}
.process-figur {
  display: flex;
}

/* Dashboard css */
.dasboard-card .card-title {
  font-size: 16px !important;
  margin-bottom: 10px;
}
.dasboard-card .card-text {
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
}
.dasboard-card {
  border-radius: 22px !important;
  border: none !important;
  margin-bottom: 10px;
}
.card-title-para {
  font-size: 36px !important;
  line-height: 36px !important;
}
.card-icon-box-1 {
  width: 131px;
  height: 149px;
  border-radius: 11px;
  background: #6a2c0a;
  box-shadow: 7px 19px 42px 0px rgba(0, 0, 0, 0.44);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dasboard-card .card-img-overlay {
  padding: 2rem;
}
.card-blue {
  background: var(
    --Gradient-02,
    linear-gradient(180deg, #48affe 0%, #486dfe 100%)
  );
  /* box-shadow: 0px 43px 41px -31px rgba(40, 155, 255, 0.75); */
}
.card-orange {
  background: var(
    --Gradiet-01,
    linear-gradient(147deg, #f6c11f -8.79%, #f1591d 83.36%)
  );
  /* box-shadow: 0px 43px 41px -31px rgba(241, 89, 17, 0.65); */
}
.card-sky {
  background: var(
    --Gradient-03,
    linear-gradient(180deg, #5de7ff 0%, #2eaae7 100%)
  );
  /* box-shadow: 0px 43px 41px -31px rgba(40, 155, 255, 0.75); */
}
.card-green {
  background: var(
    --Gradient-04,
    linear-gradient(180deg, #4ede67 0%, #4bd2a6 100%)
  );
  /* box-shadow: 0px 43px 41px -31px rgba(46, 223, 159, 0.66); */
}
.card-icon-orange {
  background: #6a2c0a;
  box-shadow: 7px 19px 42px 0px rgba(0, 0, 0, 0.44);
}
.card-icon-blue {
  background: #283d86;
  box-shadow: 7px 19px 42px 0px rgba(0, 0, 0, 0.44);
}
.card-icon-sky {
  background: #145270;
  box-shadow: 7px 19px 42px 0px rgba(0, 0, 0, 0.44);
}
.card-icon-green {
  border-radius: 11px;
  background: #3d6f4d;
  box-shadow: 7px 19px 42px 0px rgba(0, 0, 0, 0.44);
}

.modal-content {
  border-radius: 30px !important;
  padding: 20px;
}

.model-btn .btn {
  border-radius: 30px;
  padding: 0.5rem 3rem;
}
.notify-model .form-select {
  padding: 0.9rem 0.75rem;
  font-weight: 500;
  color: #000;
  background-color: #808b9612 !important;
  border: none;
  font-size: 14px;
}
.gender-chart {
  height: 420px !important;
  width: 420px !important;
  margin: auto;
}

/* Support Detail css */

.msg-body ul li.sender {
  display: block;
  width: 100%;
  position: relative;
}
.msg-body ul li {
  list-style: none;
  margin: 15px 0;
}
.msg-body ul li.sender p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #dff7ffbd;
  display: inline-block;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
  margin-left: 40px;
}
.msg-body ul {
  overflow: hidden;
  padding-left: 0px;
}
.msg-body ul li.repaly {
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
}
.msg-body ul li.repaly p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #808b961f;
  display: inline-block;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
  margin-left: 40px;
}
.send-feild {
  border: none !important;
  background-color: #e3e3e37a !important;
  padding: 15px !important;
}
.send-box .input-group-text {
  background-color: #f2f2f2 !important;
  border: 0 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.send-box {
  position: absolute;
  bottom: 0;
  width: 95%;
}
.form-control:focus {
  box-shadow: none !important;
}
.form-control,
.form-select {
  background-color: #efefef !important;
  padding: 0.8rem 0.75rem !important;
  border: none !important;
}

.rate-box {
  border-radius: 5px;
  border: 1px solid #dac6a2;
  background: #f9f5ef;
  padding: 20px;
  height: 115px;
  color: #9f8453;
  position: absolute;
  right: 85px;
  top: 80px;
}
.user-info {
  display: flex !important;
}
.card-user-detail {
  padding: 35px;
}

/* Meditation css */
.meditation-info .btn-primary,
.meditation-model .btn {
  border-radius: 10px;
  font-size: 18px;
  padding: 10px 20px;
  box-shadow: 0px 10px 15px 0px #e9dfcd;
}
.meditation-model .btn {
  padding: 10px 65px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0px 10px 15px 0px #e9dfcd;
}
.card-meditation {
  border-radius: 17.041px !important;
  border: 1px solid #f8eeda !important;
  background: #fcfbf9 !important;
  padding: 20px 0px;
  height: auto;
  display: table-cell !important;
}
.card-meditation .card-title {
  margin-bottom: 0.5rem;
  color: #000;
  font-size: 24px;
}

.card-meditation .card-text {
  font-size: 16px;
  color: #8e8e8e;
  margin-top: -5px;
}

.card-meditation .card-header {
  border-radius: 17px !important;
  border: 1px solid #cdb68e;
  background: #f1e8d8;
  box-shadow: 0px 10px 30px 0px rgba(176, 155, 118, 0.3);
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin: auto;
  line-height: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-flex {
  display: flex;
}
.meditation-model .modal-content {
  padding: 20px;
}
.meditation-model .modal-title {
  line-height: 3.5;
}
.course-details {
  text-align: left;
}
.notify-sec .btn {
  background-color: #c1c9b6 !important;
  border-color: #c1c9b6 !important;
}

.icons-list img {
  margin-left: 20px;
}
.theory-list .list-title {
  font-size: 18px;
}
.theory-list .list-group-item {
  border-radius: 17.041px !important;
  border: 1px solid #f8eeda !important;
  background: #fcfbf9 !important;
  margin-bottom: 20px;
}
.icon-number {
  color: rgba(12, 17, 16, 0.5);
  font-size: 28px;
  font-weight: 600;
  width: 100% !important;
  height: 30px;
  display: flex;
  align-items: center;
  max-width: 40px;
}
/* rajinder css here  */

.theme .btn {
  padding: 10px 25px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0px 10px 15px 0px #e9dfcd;
  display: flex;
  align-items: center;
  width: 110px;
  justify-content: space-between;
}

.top-header #dropdown-basic {
  text-align: end;
}

.notify-modal-btn .btn {
  padding: 10px 25px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0px 10px 15px 0px #e9dfcd;
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: center;
}

.user-table-align {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

button.add-category-btn {
  display: flex;
  align-items: center;
  width: 218px;
  justify-content: space-between;
}

.border-0.p-4.rounded-card.self-reflection-body {
  width: 80%;
  margin: auto;
  padding: 50px !important;
}

.modal-content-80 .modal-content {
  width: 70% !important;
  margin: auto;
}

.notify-model .modal-dialog {
  max-width: 500px;
  margin: 6.75rem auto !important;
}

.notify-modal-btn {
  display: flex;
  justify-content: center;
}

.form-control-lg {
  font-size: 17px !important;
}

.upld-place-click {
  position: relative;
  text-align: center;
  padding: 15px;
  border: 2px dashed #b79c6c;
  color: #b79c6c;
  font-size: 20px;
  font-weight: 500;
  text-decoration: underline;
  border-radius: 10px;
}
.upld-place-click p {
  margin: 0;
  font-size: 18px;
}

.table-head-btn {
  font-size: 16px !important;
}

.card-delete a {
  color: red;
  font-size: 16px;
}

.card-delete a:hover {
  color: red;
  font-size: 16px;
}
.card-theme-med p {
  /* margin: 15px 0px; */
}

.card-meditation svg {
  height: 60px;
}

.card-delete {
  margin-top: 15px;
}

.table-card {
  display: table;
}

.card-btn-add {
  border-radius: 10px;
  font-size: 15px !important;
  padding: 6px 18px !important;
  /* box-shadow: 0px 10px 15px 0px #e9dfcd; */
}

.icons-list img {
  height: 40px;
}

.img-play-card {
  height: 60px !important;
}

.list-card-play {
  display: flex !important;
  align-items: center !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: none !important;
  border-color: none !important;
  box-shadow: none !important;
}

.meditation-model .modal-title {
  line-height: 1.5;
  font-size: 2rem !important;
  text-align: left;
}

.add-upld-hide {
  position: absolute;
  top: 0;
  opacity: 0;
  bottom: 218px;
  width: 94% !important;
}

.upld-place-click .add-upld-hide {
  position: absolute;
  top: 0;
  opacity: 0;
  bottom: 218px;
  width: 94% !important;
  height: 64px;
}

.upld-audio-hide {
  position: absolute;
  top: 28%;
  height: 64px;
  width: 73% !important;
}

.add-refl-box-p .upld-audio-hide {
  position: absolute;
  top: 0;
  height: 64px;
  width: 100% !important;
}

.change-password-form label {
  color: #808b96;
  font-size: 16px;
}

.add-btn-shadow {
  box-shadow: 0px 10px 15px 0px #e9dfcd;
}

.audio-place-holder .img-play-card {
  margin-right: 20px;
  margin-left: 0px;
}

.audio-place-holder .theory-list .list-title {
  font-size: 16px;
}

.audio-place-holder .img-play-card {
  height: 40px !important;
}

.modal-content label {
  color: #808b96;
  font-size: 16px;
  /* font-weight: 500; */
}

.course-details .custom_check .modal-content label {
  color: #b79c6c !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: none !important;
  border-color: none !important;
  box-shadow: none !important;
}

.card-user-detail h5 {
  color: #808b96;
}

.self-reflection-main {
  background: #fcfbf9 !important;
}

.self-reflection-main .theory-list .list-group-item {
  background: #ffffff !important;
}

.self-reflection-main .list-title p {
  font-size: 16px;
  color: #9b9b9b;
  margin: 0;
}

button.add-category-btn.new-self-ref.btn {
  width: 260px;
  max-width: 100%;
}

.upld-audio-title {
  color: #141617;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.select-refl {
  /* font-size: 12px;  */
  font-weight: 500;
  color: #3c3d51;
}

.refl-question-card {
  border: 1px solid #cdb68e;
  border-radius: 10px;
  padding: 30px;
  background: #f1e8d8;
  box-shadow: rgb(183 156 108 / 20%) 0px 8px 24px;
}

.refl-question-card .card-a {
  font-weight: 500;
}

.add-refl-box-p {
  padding: 50px 0px !important;
}

.select-refl .form-check-input {
  color: #b79c6c;
  /* background: #b79c6c; */
  margin-right: 8px;
  border: 1px solid #b79c6c;
}
.form-check-input:focus {
  border-color: #b79c6c !important;
  outline: 0;
  box-shadow: none !important;
}

.select-refl .form-check-input:checked {
  background-color: #b79c6c !important;
  border-color: #b79c6c !important;
}

.add-btn-refl {
  margin-bottom: 30px;
  display: flex;
  justify-content: end;
}

.add-btn-refl img {
  height: 40px;
  cursor: pointer;
}

.custom_check.radio ul {
  list-style: none;
  padding: 0px;
}

.custom_check .check--label-box {
  display: none;
}

.custom_check .modal-content label {
  color: #808b96;
  font-size: 14px;
  margin-bottom: 20px;
}

.custom_check_hori {
  display: flex;
}
.custom_check_hori ul {
  display: flex;
  width: 70%;
  justify-content: space-around;
  margin: auto;
}

.custom_check_hori .check--label {
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  border: 1px solid #b79c6c !important;
  height: 50px;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 70px;
}

.text-height-question {
  height: 160px;
}

.question-details-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.question-details-card .theory-list {
  display: flex !important;
  flex-direction: unset !important;
  align-items: center;
}
.question-details-card .theory-list li {
  width: 90%;
  margin-bottom: 0px;
}
.question-details-card .theory-list img {
  height: 50px !important;
}

.question-details-card .theory-list .img-play-card {
  height: 70px !important;
}

.question-details-card ul li {
  width: 45%;
  margin: 10px;
}
.question-details-card ul {
  flex-wrap: wrap;
  display: flex;
}
.custom_check_details {
  display: flex;
  align-items: center;
}

.custom_check_details .check--label {
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  width: 50px;
  border: 1px solid #b79c6c !important;
  height: 50px;
  /* margin-bottom: 10px; */
  padding: 0px !important;
  border-radius: 50%;
}
.border-0.p-4.rounded-card.self-reflection-body {
  width: 80%;
  margin: auto;
  padding: 50px !important;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.add-refl-box-p .form-control {
  background: #fff !important;
}

.add-refl-box-p .upld-place-click {
  background: #fff;
}

.custom-cursor-pointer {
  cursor: pointer;
}
.add-icon-hide {
  opacity: 0;
  bottom: 292px;
  width: 80% !important;
  height: 60px;
}

.img-upld-place {
  width: 50%;
  margin: 0 auto;
}

.img-upld-place img {
  height: 140px;
  object-fit: cover;
  width: 150px;
}

.meditation-info img {
  height: 45px;
}

.self-reflection-body .new-self-ref {
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.width-set {
  width: 180px;
}
/* harpreet code css here  */

/* condition report 11*/
.custom-checkbox-steps ul {
  padding: 0;
}
.hidden-box {
  position: absolute;
  top: 0;
  left: 0;
  appearance: none;
  opacity: 0;
  width: 54px;
  height: 51px;
}
.hidden-box:checked + .check--label {
  background-color: #e9edf7;
  height: 40px;
  border: 1px solid #11190c;
}
.hidden-box:checked + .check--label .check--label-box {
  background-color: #11190c;
}
.hidden-box:checked + .check--label .check--label-box::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.list .list-item {
  padding: 0;
  position: relative;
  list-style: none;
  margin: 0 0 10px 0;
  height: 40px;
  display: flex;
  /* align-items: center; */
  width: 100%;
}
.check--label {
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
  border: 1px solid #b79c6c !important;
  height: 50px;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  color: #b79c6c !important;
  font-size: 20px;
  font-weight: 500;
}
.check--label-box {
  display: flex;
  align-self: center;
  position: relative;
  height: 20px;
  width: 20px;
  margin: 0 20px;
  border: 1px solid #11190c;
  cursor: pointer;
}
.check--label-text {
  display: flex;
  align-self: center;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  color: #161616;
  font-size: 14px;
  font-weight: 400;
}
.hidden-box:checked + .check--label {
  background-color: #f1e8d8;
  height: 50px;
  border: 1px solid #11190c;
}
.hidden-box:checked + .check--label .check--label-box {
  background-color: #11190c;
}
.hidden-box:checked + .check--label .check--label-box::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.cursor-pointer {
  cursor: pointer;
}

.profile-image {
  height: 40px;
  width: 40px;
}

.verify-pics {
  width: 100px;
  height: 60px;
}
.formik-errors {
  color: red;
}

.backBtn {
  width: 120px !important;
}

.backpad {
  position: relative;
  left: -27px;
  margin-left: 35px;
  padding-bottom: 10px;
}

.card_main {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0.375rem;
  height: calc(100% - 30px);
  margin-bottom: 1.563rem;
  position: relative;
  transition: all 0.5s ease-in-out;
  padding: 40px 15px;
}
.input_file_f input {
  width: 100%;
  height: 100%;
}
.input_file_f {
  position: relative;
  text-align: center;
}
.input_file_f img {
  height: 172px;
  width: 172px;
  border-radius: 109px;
  object-fit: cover;
}
.phone_input select {
  width: 100px;
}

.icons_list ul li svg {
  width: 24px;
  fill: #b79c6c;
}
.icons_right p {
  margin: 0;
  font-size: 13px;
}
.icons_list ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
.icons_list ul li {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 14px 10px;
}
.icons_right {
  padding-left: 20px;
}
.card_small {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0.375rem;
  height: calc(100% - 30px);
  margin-bottom: 1.563rem;
  position: relative;
  transition: all 0.5s ease-in-out;
  padding: 18px 15px;
}

.social ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0 10px;
}
.social ul li {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.social ul li svg {
  width: 24px;
  fill: #b79c6c;
}
.social ul li p {
  margin: 0;
}
.head h2 {
  font-size: 16px;
}
.head h2::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  right: 0;
  background: #0000001a;
  height: 1px;
  top: 52px;
}

.social ul li p {
  margin-left: 10px;
  background: #b79c6c4d;
  width: 90%;
  padding: 12px 10px;
  color: #b79c6c;
}

.toggle {
  margin-top: 40px;
}
.dlt_acount {
  margin-top: 40px;
}

.card_main label {
  font-size: 13px;
  font-weight: 500;
}

.inputs_main input::placeholder {
  font-size: 13px;
}
.inputs_main select {
  font-size: 14px;
  color: #000 !important;
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  background-color: #efefef !important;
}
.sub_btn .btn {
  padding: 10px 28px;
}

.icons_right h6 {
  font-size: 14px;
}

.dlt_acount .btn.btn-danger {
  margin-top: 13px;
}

.toggle label {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
}

.modal_head {
  font-size: 19px;
  line-height: 26px;
  text-align: center;
}
.modal-footer {
  border: transparent !important;
}

.toggle .form-switch .form-check-input {
  width: 2.2rem;
  height: 1.2rem;
}
.toggle .form-switch .form-check-input:checked {
  background-color: #b79c6c !important;
}

.set_height.row {
  height: 100%;
}

.upload_btn p {
  width: 110px;
  border-radius: 4px;
  height: 43px;
  background: #b79c6c;
  margin: auto;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 12px;
}
.upload_btn {
  position: relative;
  margin-top: 17px;
  display: block;
}
.upload_btn input {
  position: absolute;
  top: 0;
  opacity: 0;
  left: 0;
  right: 0;
}

.inputs_main input {
  font-size: 14px;
}

.modal-footer {
  border: none;
}

.custom_check.radio ul li span {
  position: absolute;
  top: 12px;
  bottom: 0;
  left: 14px;
  color: #baa072;
  font-size: 18px;
  font-weight: 500;
}
.custom_check.radio ul li {
  position: relative;
  margin-bottom: 10px;
}
.custom_check ul li input {
  padding: 14px 45px !important ;
  border-radius: 5px;
}

.custom_check_hori ul li .hidden-box {
  padding: 2px !important;
}

.hide {
  appearance: none !important;
  opacity: 0 !important;
}

.multiple_answer .card-a.correct {
  background: #b79c6c;

  color: #fff;
}
.multiple_answer .card-a {
  background: #b79c6c52;
  padding: 10px 10px;
  border-radius: 5px;
  line-height: 30px;
}
.question-btn img {
  height: 30px;
  margin-left: 15px;
}

.question-btn p {
  font-size: 18px;
  font-weight: 500;
}

.add-btn-refl .btn {
  width: 120px !important;
  padding: 8px 15px !important;
}

.course-details label {
  font-weight: 500;
  font-size: 18px;
}

.icons-list .edit {
  border: 1px solid #000;
  border-radius: 50%;
}
.checked-answer {
  background-color: #f1e8d8;
  height: 50px;
  border: 1px solid #11190c;
}

.add-image-upld-hide-icon {
  position: absolute;
  top: 0;
  opacity: 0;
  bottom: 218px;
  width: 64% !important;
  left: 18%;
  height: 140px;
}

.add-image-upld {
  position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.width-70-card {
  width: 70%;
}

button.add-new-question-btn.btn {
  width: 185px !important;
}

button.add-new-question-btn.w-225 {
  width: 170px !important;
  font-size: 14px;
}

.editorClassName {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 0.2px solid #edeff4 !important;
  border-radius: 10px;
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  min-height: 300px;
}

.editorClassName h1 {
  color: inherit !important;
}

.rounded-card.faqs.card {
  padding: 40px 40px;
  margin-top: 30px;
}

.faq-label label {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-right: 30px;
}
.faq-label input {
  border: 1px solid #000;
  padding: 8px 10px;
  width: 100%;
}

.faq-label {
  margin-top: 23px;

  margin-bottom: 27px;
}
.rounded-card.faqs.card button {
  width: 150px;
  margin: auto;
  margin-top: 35px;
}

.rounded-card.faqs.card a {
  color: #000;
}
.rounded-card.faqs.card h2 {
  font-size: 30px;
}

.rounded-card.faqs.card button svg {
  margin-left: 8px;
}

.dasboard-card {
  height: 210px;
}

.category-label label {
  text-align: left !important;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}

span.width-set {
  width: 85px;
  display: block;
}

.text-center.card-meditation.card a.card-btn-add {
  background-color: #b79c6c !important;
  border-color: #b79c6c !important;
  color: #fff;
}

.certificate-image {
  height: 80px;
  width: 80px;
}

.cmn-top-fields .form-control,
.form-select {
  background-color: #f8f9fa !important;
  padding: 0.8rem 0.75rem !important;
  border: none !important;
  border-radius: 10px !important;
}

.booking-details-box {
  padding: 30px;
  background-color: #ffffff !important;
  border-radius: 10px;
  margin-top: 20px;
}

.sign-up-card img {
  width: 400px;
}

.card_small .form-switch .form-check-input:focus {
  outline: none; /* Remove the focus outline */
  box-shadow: none; /* Remove any focus shadow */
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e);
}

.card_small .form-check-input:checked {
  background-color: #b79c6c !important;
  border-color: #b79c6c !important;
}

.card_small .toggle .form-switch .form-check-input:checked {
  background-color: #b79c6c !important;
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}
